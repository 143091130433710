import { Stack, PrimaryButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";
import animationData from "../../assets/knowledge_assistant_explainer.json";
import ReactMarkdown from "react-markdown";
import DOMPurify from "dompurify"; // Import DOMPurify

import styles from "./Answer.module.css";
import Lottie from "lottie-react";
import { CustomError } from "../../api/CustomError";

interface Props {
    customError: CustomError;
    onRetry: () => void;
}

export const AnswerError = ({ customError, onRetry }: Props) => {
    const sanitizedError = DOMPurify.sanitize(customError.message ?? "");
    let errorDetails;
    if (customError.statusCode === 405) {
        try {
            console.log(sanitizedError.replace("Error: ", "").replaceAll("'", '"'))
            errorDetails = JSON.parse(sanitizedError.replace("Error: ", "").replaceAll("'", '"'));
            errorDetails.description = errorDetails?.description.trim().split('\n').map((line: string) => line.trim()).join('\n');
        } catch (e) {
            errorDetails = { title: "Unknown error", subTitle: "", description: "" };
        }
    }
    return (
        <Stack className={styles.answerContainer} verticalAlign="space-between">
            <Stack.Item grow className={styles.answerText}>
                {customError.statusCode === 405 ? (
                    <>
                        <div className={styles.trialAnimationContainer}>
                            <Lottie animationData={animationData} loop={true} style={{ height: "100%", width: "100%" }} />
                        </div>
                        <h3 className={styles.title}>{errorDetails?.title}</h3>
                        <h4 className={styles.subTitle}>{errorDetails?.subTitle}</h4>
                        <ReactMarkdown>{errorDetails?.description}</ReactMarkdown>
                    </>
                ) : (
                    <>
                        <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />
                        <ReactMarkdown>{sanitizedError}</ReactMarkdown>
                        <PrimaryButton className={styles.retryButton} onClick={onRetry} text="Retry" />
                    </>
                )}
            </Stack.Item>

        </Stack>
    );
};
